<template>
  <v-app>
    <SonyEnvironmentSystemBar />
    <v-app-bar app dark class="bg-black" dense>
      <v-container fluid>
        <v-row align="center" justify="space-between">
          <v-col cols="3">
            <SelectedProgram v-if="!loading" />
            <SelectedProgramDropdown class="pr-15" />
          </v-col>
          <v-col cols="3">
            <v-row align="center" justify="end">
              <slot name="right-content">
                <CountryFlag
                  v-if="selectedCountry && (selectedCountry.name == 'USA' || selectedCountry.name == 'CAN')"
                  :country="selectedCountry.name.toLowerCase()"
                  size="normal"
                />
                <LocaleDropdown :routeToDashboard="false" />
              </slot>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:extension v-if="showMenu"> <EurTopMenu /></template>
    </v-app-bar>
    <v-main class="login-bg" v-if="!loading">
      <router-view />
    </v-main>
    <v-footer :app="footerDark" :dark="footerDark" :absolute="true">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import SelectedProgram from "@/components/SelectedProgram.vue";
import LocaleDropdown from "@/gapp-components/components/fields/LocaleDropdown.vue";
import SelectedProgramDropdown from "@/gapp-components/components/fields/SelectedProgramDropdown.vue";
import EurTopMenu from "@/views/eur/EurTopMenu.vue";
import SonyEnvironmentSystemBar from "@/views/SonyEnvironmentSystemBar.vue";
import CountryFlag from "vue-country-flag";
import { mapGetters } from "vuex";

export default {
  name: "LayoutPublicEur",
  props: {
    showMenu: {
      type: Boolean,
      default: false
    },
    footerDark: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CountryFlag,
    SonyEnvironmentSystemBar,
    SelectedProgram,
    SelectedProgramDropdown,
    LocaleDropdown,
    EurTopMenu,
    Footer
  },
  data: () => ({
    loading: true
  }),
  async mounted() {
    // clear existing session
    this.$auth.logout();

    this.loading = true;
    let programKey = this.$sonyProgram.getProgramKeyByDomain();
    await this.$api
      .get("/api/programs/byKey/" + programKey + "/public")
      .then(({ data }) => {
        let program = data;
        return Promise.all([
          this.$auth.storeSelectProgram(program),
          this.$auth.storeSelectClient(program.programGroup.client)
        ]);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedCountry"])
  }
};
</script>
