<template>
  <v-tabs centered v-model="tab" :value="tab" color="blue">
    <template v-for="(rootItem, i) in items">
      <v-tab :key="i" :to="rootItem.to">
        <span>{{ rootItem.text }}</span>
      </v-tab>
    </template>
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EurTopMenu",
  data: () => ({
    tab: 1
  }),
  computed: {
    ...mapGetters(["breadcrumbs", "selectedParticipant", "selectedLocale"]),
    items() {
      return [
        {
          text: this.$i18n.translate("Rebates"),
          to: { name: "eurList" }
        },
        {
          text: this.$i18n.translate("FAQ"),
          to: { name: "eurFaq" }
        },
        {
          text: this.$i18n.translate("Track My Rebate"),
          to: { name: "eurTrack" }
        },
        {
          text: this.$i18n.translate("Service & Support"),
          to: { name: "eurListServiceAndSupport" }
        }
      ];
    }
  }
};
</script>

<style scoped>
.v-tabs .v-tab {
  border: 1px solid rgba(49, 182, 238, 0.5) !important;
  border-radius: 4px;
}
</style>
